import React, {Component} from 'react';

class Spacer extends Component {
	render() {
		return (
			<div style={{height: this.props.height}}>

			</div>
		);
	}
}

export default Spacer;
