import React from 'react';
import Query from "../querying/Query";
import Page from "../components/Page";
import Spacer from "../components/Spacer";
import {useParams} from "react-router-dom";
import RECHTLICHES_QUERY from "../querying/queries/rechtliches";
import ReactMarkdown from "react-markdown";

import "./Rechtliches.scss";


const Rechtliches = () => {
	return (
		<div>
			<Query query={RECHTLICHES_QUERY(useParams().url)}>
				{(data) => {
					if(data.data.rechtliches.length === 0) {
						window.location.href = window.location.origin + "/404";
					}
					else {
						document.getElementById("pageDesc").setAttribute("content", data.data.rechtliches[0].teaser);

						return (
							<Page title={data.data.rechtliches[0].titel} teaser={data.data.rechtliches[0].teaser}>
								<div className={"legalText"}><ReactMarkdown source={data.data.rechtliches[0].inhalt} escapeHtml={false} /></div>
								<Spacer height={"150px"}/>
							</Page>
						)
					}
				}}
			</Query>
		</div>
	);
};

export default Rechtliches;
