import gql from "graphql-tag";

const CTA_QUERY = gql`
	query CTA {
		cta {
			titel,
			teaser,
			bild {
				formats
			},
			empfaenger
		}
	}
`;

export default CTA_QUERY;
