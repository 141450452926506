import React, {Component} from 'react';
import Title from "./Title";

import "./Section.scss";


class Section extends Component {
	render() {
		return (
			<div className={"Section"}>
				<Title title={this.props.title} teaser={this.props.teaser} isInPageHeader={false} />
				{this.props.children}
			</div>
		);
	}
}

export default Section;
