import React, {Component} from 'react';

import "./ContactCard.scss";
import IconButtonRow from "./IconButtonRow";

class ContactCard extends Component {
	render() {
		return (
			<div className={"ContactCard hervorgehoben"}>
				<div className={"imageWrapper"}>
					<img src={"//cms.sorglos-bauen.com" + this.props.data.bild.formats.small.url} alt={""}/>
				</div>
				<div className={"contentWrapper"}>
					<h2>{this.props.data.name}</h2>
					<h3>{this.props.data.position}</h3>
					<div className={"separator"}/>
					<p>{this.props.data.info}</p>
					<IconButtonRow className={"contactOptions"} data={[
						{
							"id": "1",
							"titel": "Telefon",
							"icon_bezeichnung": "local_phone",
							"ziel": "tel:" + this.props.data.telefonnummer,
							"angezeigt": true
						},
						{
							"id": "2",
							"titel": "Email",
							"icon_bezeichnung": "mail_outline",
							"ziel": "mailto:" + this.props.data.email,
							"angezeigt": true
						}
					]}/>
				</div>
			</div>
		);
	}
}

export default ContactCard;
