import React, {Component} from 'react';
import {ListAltOutlined, LocalOfferOutlined, DashboardOutlined, TodayOutlined} from '@material-ui/icons';

import "./RealEstateCard.scss";

class RealEstateCard extends Component {
	render() {
		return (
			<div className={"RealEstateCard"} onClick={() => {window.location.href = `${window.location.origin}/immobilie/${this.props.data.url}`}}>
				<div className={"preview"}>
					<div className={"backgroundImage"} style={{backgroundImage: `url('//cms.sorglos-bauen.com${this.props.data.vorschaubild.formats.medium.url}')`}} />
					<div className={"backgroundGradient"}/>
					<h3 className={"objectTitle"}>{this.props.data.titel}</h3>
				</div>
				<div className={"details"}>
					<div className={"entry"}>
						<ListAltOutlined />
						<p>{this.props.data.fertiggestellt?"Fertiggestellt":"Im Bau"}</p>
					</div>
					<div className={"divider"} />
					<div className={"entry"}>
						<LocalOfferOutlined />
						<p>{
							this.props.data.kategorie
								.replaceAll("ae", "ä")
								.replaceAll("oe", "ö")
								.replaceAll("ue", "ü")
						}</p>
					</div>
					<div className={"divider"} />
					<div className={"entry"}>
						<DashboardOutlined />
						<p>{this.props.data.groesseJeEinheit}</p>
					</div>
					<div className={"divider"} />
					<div className={"entry"}>
						<TodayOutlined />
						<p>{(new Date(this.props.data.fertigstellungsdatum).getMonth() + 1 < 10)?"0":null}{new Date(this.props.data.fertigstellungsdatum).getMonth() + 1} / {new Date(this.props.data.fertigstellungsdatum).getFullYear().toString().substr(2,2)}</p>
					</div>
				</div>
			</div>
		);
	}
}

export default RealEstateCard;
