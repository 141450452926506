import gql from "graphql-tag";

const IMMOBILIE_QUERY = function(url) {
	return gql`
		query Immobilie {
			immobilies(where: {url: "${url}"}) {
				titel,
				vorschaubild {
					url
				},
				fertiggestellt,
				kategorie,
				groesseJeEinheit,
				fertigstellungsdatum,
				heizung,
				dachtyp,
				anzahl,
				wohneinheitenJeGebaeude,
				listings {
					id, 
					titel, 
					url
				},
    			bauzeitenplan {
          			url
        		},
    			Baufortschrittsbericht {
					titel,
					datum, 
					inhalt,
					bilder {
						url, 
						caption
					}
        		},
				galerie {
					url, 
					caption
				}
			}	
		}
	`;
};

export default IMMOBILIE_QUERY;
