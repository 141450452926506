import gql from "graphql-tag";

function FEEDBACK_QUERY(url) {
	return (gql`
		query Feedback {
			feedback {
				titel,
				teaser,
				empfaenger
			},
			feedbackAnfrages(where: {url: "test"}) {
				id,
				projekt {
					titel
				}
			}
		}
	`);
}

export default FEEDBACK_QUERY;
