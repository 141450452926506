import gql from "graphql-tag";

const IMMOBILIEN_QUERY = gql`
	query Immobilien {
		immobilien {
			titel,
			teaser
		},
		immobilies {
			id, 
			url,
			titel,
			vorschaubild {
				formats
			},
			fertiggestellt,
			kategorie,
			groesseJeEinheit
			fertigstellungsdatum
		}	
	}
`;

export default IMMOBILIEN_QUERY;
