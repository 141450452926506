import React, {Component} from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import IMMOBILIE_QUERY from "../querying/queries/immobilie";
import Query from "../querying/Query";
import gql from "graphql-tag";

import CTA from "../components/CTA";
import RealEstateHero from "../components/RealEstateHero";
import MetaOverviewCard from "../components/MetaOverviewCard";

import "./Immobilie.scss";
import Spacer from "../components/Spacer";
import ProgressReportCard from "../components/ProgressReportCard";


const options = {
	settings: {
		autoplaySpeed: 0,
		disableKeyboardControls: false,
		disablePanzoom: false,
		disableWheelControls: false,
		hideControlsAfter: 0,
		lightboxTransitionSpeed: 0.6,
		lightboxTransitionTimingFunction: 'linear',
		overlayColor: 'rgba(0, 0, 0, 0.9)',
		slideAnimationType: 'fade',
		slideSpringValues: [300, 200],
		slideTransitionSpeed: 0.6,
		slideTransitionTimingFunction: 'linear'
	},
	buttons: {
		showAutoplayButton: true,
		showCloseButton: false,
		showDownloadButton: false,
		showFullscreenButton: false,
		showNextButton: true,
		showPrevButton: true,
		showThumbnailsButton: false
	},
	translations: {
		autoplayText: 'Play',
		closeText: 'Schließen',
		downloadText: 'Herunterladen',
		fullscreenText: 'Vollbild',
		nextText: 'Weiter',
		pauseText: 'Zurück',
		previousText: 'Vorheriges',
		thumbnailsText: 'Thumbnails ausblenden',
		zoomOutText: 'Rauszoomen'
	}
}

class Immobilie extends Component {
	render() {
		return (
			<div>
				<Query query={IMMOBILIE_QUERY(window.location.pathname.split("/")[2].toString())}>
					{(data) => {
						if(data.data.immobilies.length === 0) {
							return (<Query query={gql`query fail {on: purpose}`} />);
						}
						else {
							document.getElementById("title").innerText = data.data.immobilies[0].titel + " | sorglos bauen GmbH";
							document.getElementById("pageDesc").setAttribute("content", data.data.immobilies[0].teaser);

							let bauzeitenplan = ((data.data.immobilies[0].bauzeitenplan !== null) ? (
								<div className={"bauzeitenplan"}>
									<h2>Bauzeitenplan</h2>
									<div className={"ganttImageWrapper"}>
										<img className={"ganttImage"} alt={"Bauzeitenplan"}
											 src={"//cms.sorglos-bauen.com" + data.data.immobilies[0].bauzeitenplan.url}/>
									</div>
								</div>
							) : null);

							let baufortschritt = ((data.data.immobilies[0].Baufortschrittsbericht.length !== 0) ? (
								<div className={"baufortschrittsbericht"}>
									<h2>Baufortschritt</h2>
									{data.data.immobilies[0].Baufortschrittsbericht.map((bericht) => {
										return (<ProgressReportCard data={bericht}/>);
									})}
								</div>
							) : null);

							let galerie = ((data.data.immobilies[0].galerie !== null) ? (
								<div className={"galerie"}>
									<h2>Galerie</h2>
									<div className={"galerieBilder"}>
										{data.data.immobilies[0].galerie.map((bild) => {
											return (
												<img className={"galerieBild"} alt={bild.caption}
													 src={"//cms.sorglos-bauen.com" + bild.url}/>
											);
										})}
									</div>
								</div>
							) : null);

							return (
								<div className={"SpecialPage Immobilie"}>
									<SRLWrapper options={options}>
										<RealEstateHero data={data.data.immobilies[0]}/>
										<MetaOverviewCard data={data.data.immobilies[0]}/>

										<div className={"details"}>
											<h2 className={"small"}>Details</h2>
											<div className={"detailsList"}>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Anzahl</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].anzahl}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Kategorie</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].kategorie}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Wohneinheiten je Gebäude</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].wohneinheitenJeGebaeude}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Größe je Wohneinheit</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].groesseJeEinheit}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Dach</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].dachtyp}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Heizung</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].heizung}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Fertiggestellt</td>
														<td className={"detailValue"}><p>{data.data.immobilies[0].fertiggestellt?"Ja":"Nein"}</p></td>
													</tr>
												</table>
												<table className={"detail"}>
													<tr>
														<td className={"detailTitle"}>Fertigstellung</td>
														<td className={"detailValue"}><p>{(new Date(data.data.immobilies[0].fertigstellungsdatum).getMonth() + 1 < 10)?"0":null}{new Date(data.data.immobilies[0].fertigstellungsdatum).getMonth() + 1} / {new Date(data.data.immobilies[0].fertigstellungsdatum).getFullYear().toString().substr(2,2)}</p></td>
													</tr>
												</table>
											</div>
										</div>

										{ bauzeitenplan }
										{ baufortschritt }
										{ galerie }

									</SRLWrapper>
									<Spacer height={"100px"} />
									<CTA />
								</div>
							);
						}
					}}
				</Query>
			</div>
		);
	}
}

export default Immobilie;
