import React, {Component} from 'react';
import Query from "../querying/Query";
import NAVBAR_QUERY from "../querying/queries/navbar";

import "./Navbar.scss";
import {MenuOutlined} from "@material-ui/icons";


class Navbar extends Component {
	render() {
		return (
			<Query query={NAVBAR_QUERY}>
				{(data) => {
					return (
						<nav className={"Navbar"}>
							<img src={`//cms.sorglos-bauen.com${data.data.navigation.logo.url}`} alt={""} height="50px" className={"logo"} onClick={() => {window.location.href = window.location.origin}}/>
							<div className={"menuButton"} onClick={() => {
								let list = document.querySelectorAll(".links")[0];
								if (list.classList.value === "links shown") {
									list.classList = "links hidden";
								}
								else {
									list.classList = "links shown";
								}
							}}>
								<MenuOutlined />
							</div>
							<div className={"links hidden"}>
								{data.data.navigation.link.map((link) => {
									if(link.angezeigt) {
										return (
											<div key={link.bezeichnung} className={link.hervorgehoben?"button hervorgehoben":"button"} >
												<a href={link.ziel}>
													<p>{link.bezeichnung}</p>
												</a>
											</div>
										);
									}
									return null;
								})}
							</div>
						</nav>
					);
				}}
			</Query>
		);
	}
}

export default Navbar;
