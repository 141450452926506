import React, {Component} from 'react';

import "./Footer.scss";
import Query from "../querying/Query";
import FOOTER_QUERY from "../querying/queries/footer";
import IconButtonRow from "./IconButtonRow";


class Footer extends Component {
	render() {
		return (
			<Query query={FOOTER_QUERY}>
				{(data) => {
					return (
						<div className={"Footer"}>
							<div className={"info"}>
								<h1>{data.data.footer.titel}</h1>
								<p>{data.data.footer.beschreibung}</p>
								<IconButtonRow data={data.data.footer.icon_button}/>
							</div>
							<div className={"linklist"}>
								<h2>Unternehmen</h2>
								<ul>
									{data.data.footer.unternehmen_links.map((link) => {
										if(link.angezeigt) {
											return (<li key={link.id}><a href={link.ziel}>{link.bezeichnung}</a></li>);
										}
										return null;
									})}
								</ul>
							</div>
							<div className={"linklist"}>
								<h2>Informationen für</h2>
								<ul>
									{data.data.footer.zielgruppen_links.map((link) => {
										if(link.angezeigt) {
											return (<li key={link.id}><a href={link.ziel}>{link.bezeichnung}</a></li>);
										}
										return null;
									})}
								</ul>
							</div>
							<div className={"linklist"}>
								<h2>Weitere Links</h2>
								<ul>
									{data.data.footer.weitere_links.map((link) => {
										if(link.angezeigt) {
											return (<li key={link.id}><a href={link.ziel}>{link.bezeichnung}</a></li>);
										}
										return null;
									})}
								</ul>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default Footer;
