import gql from "graphql-tag";

const KONTAKT_QUERY = gql`
	query Kontakt {
		kontakt {
			titel,
			teaser,
			datenschutzhinweis,
			empfaenger
		}
	}
`;

export default KONTAKT_QUERY;
