import gql from "graphql-tag";

const FOOTER_QUERY = gql`
	query Footer {
		footer {
			titel,
			beschreibung,
			unternehmen_links {
				id,
				bezeichnung,
				ziel,
				angezeigt
			},
			zielgruppen_links {
				id,
				bezeichnung,
				ziel,
				angezeigt
			},
			weitere_links {
				id,
				bezeichnung,
				ziel,
				angezeigt
			},
			icon_button {
				id,
				titel,
				icon_bezeichnung,
				ziel,
				angezeigt
			}
		}
	}
`;

export default FOOTER_QUERY;
