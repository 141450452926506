import React, {Component} from 'react';
import Query from "../querying/Query";
import Page from "../components/Page";
import Spacer from "../components/Spacer";
import ContactForm from "../components/ContactForm";
import FEEDBACK_QUERY from "../querying/queries/feedback";

class Kontakt extends Component {


	render() {
		return (
			<div>
				<Query query={FEEDBACK_QUERY(window.location.href.split("/")[4])}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.feedback.teaser);

						return (
							<Page title={data.data.feedback.titel} teaser={data.data.feedback.teaser}>
								<ContactForm mailrecipient={data.data.feedback.empfaenger} project={data.data.feedbackAnfrages[0].projekt.titel} id={data.data.feedbackAnfrages[0].id} feedback/>
								<Spacer height={"150px"} />
							</Page>
						)
					}}
				</Query>
			</div>
		);
	}
}

export default Kontakt;
